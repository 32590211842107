.report * {
   margin: 0;
   padding: 0;
   text-indent: 0;
}

.report .s1 {
   color: black;
   font-family: Arial, sans-serif;
   font-style: normal;
   font-weight: normal;
   text-decoration: none;
   font-size: 10px;
}

.report h1 {
   color: black;
   font-family: Arial, sans-serif;
   font-style: normal;
   font-weight: bold;
   text-decoration: none;
   font-size: 10pt;
}

.report .s2 {
   color: black;
   font-family: Arial, sans-serif;
   font-style: normal;
   font-weight: bold;
   text-decoration: none;
   font-size: 9pt;
}

.report .s3 {
   color: black;
   font-family: Arial, sans-serif;
   font-style: italic;
   font-weight: normal;
   text-decoration: none;
   font-size: 9pt;
}

.report .s4 {
   color: black;
   font-family: Arial, sans-serif;
   font-style: normal;
   font-weight: normal;
   text-decoration: none;
   font-size: 9pt;
   font-weight: bold;
   /* text-align: left; */
   padding-left: 0!important;
}

.report h2 {
   color: black;
   font-family: Arial, sans-serif;
   font-style: normal;
   font-weight: bold;
   text-decoration: none;
   font-size: 9pt;
}

.report p {
   color: black;
   font-family: Arial, sans-serif;
   font-style: italic;
   font-weight: normal;
   text-decoration: none;
   font-size: 9pt;
   margin: 0pt;
}

.report .s5 {
   color: black;
   font-family: Arial, sans-serif;
   font-style: normal;
   font-weight: bold;
   text-decoration: none;
   font-size: 8pt;
}

.report .tCell {
   border-top-style: solid;
   border-top-width: 1pt;
   border-left-style: solid;
   border-left-width: 1pt;
   border-bottom-style: solid;
   border-bottom-width: 1pt;
   border-right-style: solid;
   border-right-width: 1pt;
   padding-left: 5px;
   padding-right: 5px!important;
   font-size: 14px;
   color: #545454;
   font-weight: 400;
}

.report .firstTCell {
   border-top-style: solid;
   border-top-width: 1pt;
   border-bottom-style: solid;
   border-bottom-width: 1pt;
   border-right-style: solid;
   border-right-width: 1pt;
}

.report .lastTCell {
   border-top-style: solid;
   border-top-width: 1pt;
   border-left-style: solid;
   border-left-width: 1pt;
   border-bottom-style: solid;
   border-bottom-width: 1pt;
}

.report .pageBreak {
   page-break-after: always;
}

.report-wrap {
   cursor: pointer;
   display: flex;
   flex-direction: column;
}

.report-wrap i {
   margin-bottom: 30px;
   font-size: 25px;
}

.em-sections strong {
   margin-right: 5px;
}

.em-sections h5 {
   margin-bottom: 30px;
   text-decoration: underline;
}

.report>div {
   margin: 0 auto;
}

.report td {
   padding-left: 5px!important;
   padding-right: 5px!important;
   font-family: Arial, sans-serif!important;
}

.incident_table {
   /* max-width:850px; */
   margin: 0 auto;
}

.incident_table td {
   padding-left: 8px !important;
   padding-right: 8px !important;
}

.incident_table input[type=checkbox]{
   margin-right: 10px;
}
